module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Covid Travel","short_name":"CovidTravel","start_url":"/?utm_source=A2HS&utm_medium=CovidTravel","background_color":"#7E57C2","theme_color":"#7E57C2","display":"standalone","scope":"/","orientation":"portrait","icon":"src/static/favicons/travel-logo-purple.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7afc248a9b8604b5732d116a22a563e9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-137372309-2","head":true,"anonymize":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

import './src/styles/uikit.less';

export const onServiceWorkerUpdateReady = () => {
    // const answer = window.confirm(
    //     `This application has been updated. Reload to display the latest version.`
    // )
    // if (answer === true) {
    //     window.location.reload()
    // }
    console.log("NEW SW IS LIVE")
}
